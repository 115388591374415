<template>
    <Header active_menu="2"/>
    <div class="box_blue"></div>
    <img src="../assets/img/yewu.png" alt="" class="img_yewu flexjc">
    <div class="flexjc">
        <div class="content">
        <div class="weizhi"><span>{{ $t('您的位置：首页') }}</span> <span class="dayu">&gt;</span> <span >{{$t('公司信息')}}</span> <span>&gt;</span> <span class="co5">{{ $t('业务介绍')}}</span></div>
        <div class="flexjc flexjc_inner" style="margin:75px auto 35px">
            <div class="left">
                <img src="../assets/img/yewu2.png" alt="" class="yewu2">
                <div class="text1">{{ $t('OSR・大阪船場流通MART') }}</div>
                <div class="text2">{{ $t('以大阪船场为据点的展览/商务会议业务') }}</div>
                <div class="text3">
                    {{
                            $t('以⼤阪船场为据点的展览、商务会议业务。2021年OSR线下展销会流通⾦额约40亿⽇元。通过线下展销会模式，为中国企业客户提供⽇本地区的线下展示会服务，将产品批发销售渠道扩展到⽇本线下1200家零售采购店，出货、销售、海关、物流、业务咨询等相关业务可全部交由傲可凡公司进⾏代理运营。')
                    }}
                </div>
                <div class="flex" style="align-items:flex-start">
                    <span>・利用费用：</span>
                    <div class="money">
                        <span>{{ $t('供应商- 会費10,000円/月（税前）＋永久基本展位费15,000円/月（税抜）＋应收账款汇款手续费5％') }}</span>
                        <div class="test" style="margin-bottom:30px">{{ $t('※单独的展览押金 50,000日元（退出时退还）') }}</div>
                        <div class="free" style="margin:24px 0">买家-免费</div>
                    </div>
                </div>
                <div class="flex" style="align-items:flex-start">
                    <span>・特征：</span>
                    <div class="money">
                        <span>{{ $t('毎月第一周日・周一・周二并在3月和周二举行展览业务会议，每年分配金额约为40亿日元。') }}</span>
                    </div>
                </div>
            </div>
            <img class="img_one" src="../assets/img/24.png" alt="" style="max-width:658px;margin-left:70px"> 

        </div>
         <img src="../assets/img/45.png" alt=""  class="flexjc img_two" style="margin:0 auto">
        <!-- <div class="flexjc">
             <img src="../assets/img/24.png" alt="" style="max-width:658px;margin-right;:70px"> 
             <div class="netss_box">

             </div>
        </div> -->
         <div class="flexjc" style="margin:75px auto 35px">
           <img src="../assets/img/24.png" class="img_one" alt="" style="max-width:658px;margin-right:70px"> 
            <div class="left">
                <img src="../assets/img/yewu2.jpg" alt="" class="yewu2">
                <div class="text1">{{ $t('日本最大的BtoB批发采购平台') }}</div>
                <div class="text2">{{ $t('以大阪船场为据点的展览/商务会议业务') }}</div>
                <div class="text3">
                    {{
                            $t('⽇本最⼤的 BtoB 批发采购平台，每年分销⾦额约100 亿⽇元。为中国企业客户提供在⽇本NETSEA平台的 BtoB批发服务，将产品批发销售渠道扩展到⽇本 490,000 家零售商，出货、销售、海关、物流、咨询等相关业务交由傲可凡公司进⾏代理运营。今后，将在NETSEA中国平台为中国企业客户提供⽇本优质产品的BtoB采购服务。')
                    }}
                </div>
                <div class="flex" style="align-items:flex-start">
                    <span>・利用费用：</span>
                    <div class="money">
                        <span>{{ $t('供货商月額20,000円(税前)＋交易手续费8％～10％') }}</span>
                    </div>
                </div>
                <div class="flex  no_margin" style="align-items:flex-start;margin: 24px 0 40px;">
                    <span>・特征：</span>
                    <div class="money">
                        <span>{{ $t('约49万注册买家年交易金额约100亿日元') }}</span>
                    </div>
                </div>
                <a href="www.aucfan.com" style="color: #5790CB;">www.aucfan.com</a>
            </div>
        </div>
         <img src="../assets/img/46.png" alt="" style="margin: 0 auto;" class="flexjc img_bottom"> 
        <!-- <div class="COPY">COPYRIGHT(@)2022mo005_19560-{{$t('企业集团类官网')}}</div> -->
    </div>
    </div>

</template>   

<script>
// import { Swiper, SwiperSlide } from "swiper/vue";
// import { toRaw } from '@vue/reactivity'
import Header from '../components/Header2.vue'
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },
    components: {
        Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.netss_box{

}
.money {
    max-width: 453px;
    color: #666666;
    font-size: 14px;
    line-height: 21px;
}

.text3 {
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    margin: 20px 0;
}

.left {
    max-width: 608px;

}

.text2 {
    font-size: 18px;
    color: #666666;
}

.text1 {
    font-size: 28px;
    color: #333333;
    margin: 24px 0 20px;
}

.yewu2 {
    width: 130px;
    height: 61px;
}

.weizhi {
    font-size: 14px;
    font-family: Source Han Sans CN;
    color: #666666;
}

.content {
    max-width: 70%;
    margin: 48px auto;

}

.img_yewu {
    max-width: 80%;
    margin: -190px auto 0;
}

.box_blue {
    max-width: 100%;
    height: 369px;

    background: #1F4A92;
}


.img_two,
.img_bottom{
    width: 1120px;
}
@media screen and (max-width:1000px) {
    .box_blue{
        height: 150px;
    }
    .img_yewu{
        margin-top: -40px;
    }
    .content{
        margin: 20px 0;
        /* display: flex; */
    }
    .weizhi{
        font-size: 6px;
    }
    .flexjc_inner{
        margin-top: 20px !important;
    }
    .yewu2{
        width: 40px;
        height: 15px;
    }
    .img_one{
        margin-left: 0 !important;
        width: 40vw;
        height: 40vw;
        /* display: none; */
    }
    .text1{
        font-size: 8px;
    }
    .text2{
        font-size: 8px;
        /* margin-bottom: 2px !important; */
    }
    .text3{
        font-size: 6px;
        line-height: 8px;
        margin-bottom: 2px;
        margin-top: 2px;
    }
    .flex{
        font-size: 8px;
    }
    .money{
        font-size: 8px;
        line-height: 8px;
        margin: 0;
    }
    .test{
        margin: 0 !important;
    }
    .free{
        font-size: 8px !important;
        margin: 5px 0 !important;
    }
    .img_two{
        width: 80vw;
    }
    .no_margin{
        margin: 5px 0 !important;
    }
    .img_bottom{
        width: 80vw;
    }
    .COPY{
        font-size: 8px;
    }
}
</style>
